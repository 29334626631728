

import UMUM from "../library/umum.js";

export default {
  data() {
    return {

      form : {
                id : '',
                nama : '',
                file : null,
                status : '',
                keterangan : '',
                lokasi : '',
                userId : '',
                createAt : '',
                editedAt : '',
                file_old : null
            },
      src_loc : "https://maps.google.com/maps?q=",
      embed_loc : "&z=15&output=embed",
     
      list_data : [],
      list_jenis : [],
      file_path: this.$store.state.url.URL_APP + "uploads/",

      page_first: 1,
      page_last: 0,
      cari_value: "",
      cek_load_data : true,


      mdl_add: false,
      mdl2: false,
      mdl3: false,
      mdl_approve :false,
      mdl_delete :false,
      mdl_lokasi: false,
      mdl_open: false,
      mdl_edit: false,
      mdl_hapus: false,
      btn_add: false,
      UMUM : UMUM,
    }
  },
  methods: {


    getView : function(){
      this.$store.commit("shoWLoading");
      fetch(this.$store.state.url.URL_Laporan + "view", {
          method: "POST",
          headers: {
          "content-type": "application/json",
          authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({
              data_ke: this.page_first,
              cari_value: this.cari_value
          })
      })
          .then(res => res.json())
          .then(res_data => {
              this.list_data = res_data.data;
              this.page_last = res_data.jml_data;
              this.$store.commit("hideLoading");
              console.log(res_data);
      });
    },

    addData : function() {
      var formData = new FormData();
      formData.append('data', JSON.stringify(this.form))
      formData.append('file', this.form.file)

      console.log(formData);

      fetch(this.$store.state.url.URL_Laporan + "addData", {
          method: "POST",
          headers: {
            authorization: "kikensbatara " + localStorage.token
          },
          body: formData
      }).then(res_data => {
          this.Notify('Sukses Menambah Data', 'secondary', 'check_circle_outline');
          console.log(res_data);
          this.getView();
      });
    },

    removeData : function(a, b){
      fetch(this.$store.state.url.URL_Laporan + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : a, file : b})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },
    tolak_data : function(){
        fetch(this.$store.state.url.URL_Laporan + "kembalikan_data", {
            method: "POST",
            headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
            },
            body: JSON.stringify({data : this.form})
        }).then(res_data => {
          console.log(res_data);
            this.Notify('Berhasil Mengembalikan Data', 'orange', 'check_circle_outline');
            console.log("=======")
            this.getView();
        });
    },

    terima_data : function(){
      fetch(this.$store.state.url.URL_Laporan + "terima_data", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({data : this.form})
      }).then(res_data => {
          this.Notify('Laporan Selesai', 'green', 'check_circle_outline');
          this.getView();
      });
    },

    // approveLampiran: async function(){
    //     // await UMUM.notifApprove();
    //     fetch(this.$store.state.url.URL_Laporan + 'approve', {
    //         method: "POST",
    //         headers: {
    //           "content-type": "application/json",
    //           authorization: "kikensbatara " + localStorage.token
    //         },
    //         body: JSON.stringify(this.lampiran)
    //         }).then(res_data => {
    //           this.getLampiran();
    //           this.getView1(this.form.id);
    //           this.Notify ('Mempublish', 'primary', 'check_circle_outline');
    //     });
    //   },

    removeData : function(E){
      fetch(this.$store.state.url.URL_Laporan + "removeData", {
          method: "POST",
          headers: {
            "content-type": "application/json",
            authorization: "kikensbatara " + localStorage.token
          },
          body: JSON.stringify({id : this.form.id})
      }).then(res_data => {
          this.Notify('Sukses Menghapus Data', 'negative', 'check_circle_outline');
          this.getView();
      });

    },

    
    selectData : function(data){
        this.form.id = data.id
        this.form.file = this.form.file
        this.form.status = data.status
        this.form.keterangan = data.keterangan
        this.form.lokasi = data.lokasi
        this.form.createAt = data.createAt
        this.form.file_old =  data.file
    },

   

    // ====================================== PAGINATE ====================================
        Notify : function(message, positive, icon){
          this.$q.notify({
            message: message,
            color: positive,
            icon: icon,
            position : 'top',
            timeout: 500,
          })
        },
        btn_prev : function(){
            this.cek_load_data = true;
            if(this.page_first>1){
                this.page_first--
            }else{
                this.page_first = 1;
            }
            this.getView();
        },

        btn_next : function(){
            if(this.page_first >= this.page_last){
                this.page_first == this.page_last
            }else{
                this.page_first++;
            }
            this.getView();
        },

        cari_data : function(){
            this.page_first = 1;
            this.getView();
        },

   

  },

  mounted () {
    this.getView();
    var get_profile = JSON.parse(localStorage.profile);
    this.form.nama = get_profile.profile.nama;
    this.tipe = get_profile.profile.menu_klp;
  },
}
